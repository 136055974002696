@font-face {
  font-family: 'picol';
  src: url('../font/picol.eot?84118626');
  src: url('../font/picol.eot?84118626#iefix') format('embedded-opentype'),
       url('../font/picol.woff?84118626') format('woff'),
       url('../font/picol.ttf?84118626') format('truetype'),
       url('../font/picol.svg?84118626#picol') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'picol';
    src: url('../font/picol.svg?84118626#picol') format('svg');
  }
}
*/
 
 [class^="picol_"]:before, [class*=" picol_"]:before {
  font-family: "picol";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.picol_accept:before { content: '\e800'; } /* '' */
.picol_zoom_out:before { content: '\ea24'; } /* '' */
.picol_agent:before { content: '\e802'; } /* '' */
.picol_api:before { content: '\e803'; } /* '' */
.picol_apple:before { content: '\e804'; } /* '' */
.picol_application:before { content: '\e805'; } /* '' */
.picol_arrow_full_down:before { content: '\e806'; } /* '' */
.picol_arrow_full_left:before { content: '\e807'; } /* '' */
.picol_arrow_full_lowerleft:before { content: '\e808'; } /* '' */
.picol_arrow_full_lowerright:before { content: '\e809'; } /* '' */
.picol_arrow_full_right:before { content: '\e80a'; } /* '' */
.picol_arrow_full_up:before { content: '\e80b'; } /* '' */
.picol_arrow_full_upperleft:before { content: '\e80c'; } /* '' */
.picol_arrow_full_upperright:before { content: '\e80d'; } /* '' */
.picol_arrow_sans_down:before { content: '\e80e'; } /* '' */
.picol_arrow_sans_left:before { content: '\e80f'; } /* '' */
.picol_arrow_sans_lowerleft:before { content: '\e810'; } /* '' */
.picol_arrow_sans_lowerright:before { content: '\e811'; } /* '' */
.picol_arrow_sans_right:before { content: '\e812'; } /* '' */
.picol_arrow_sans_up:before { content: '\e813'; } /* '' */
.picol_arrow_sans_upperleft:before { content: '\e814'; } /* '' */
.picol_arrow_sans_upperright:before { content: '\e815'; } /* '' */
.picol_attachment:before { content: '\e816'; } /* '' */
.picol_attachment_add:before { content: '\e817'; } /* '' */
.picol_attachment_down:before { content: '\e818'; } /* '' */
.picol_avatar:before { content: '\e819'; } /* '' */
.picol_avatar_edit:before { content: '\e81a'; } /* '' */
.picol_avatar_information:before { content: '\e81b'; } /* '' */
.picol_backup:before { content: '\e81c'; } /* '' */
.picol_backup_pause:before { content: '\e81d'; } /* '' */
.picol_backup_run:before { content: '\e81e'; } /* '' */
.picol_backup_settings:before { content: '\e81f'; } /* '' */
.picol_backup_stop:before { content: '\e820'; } /* '' */
.picol_badge_accept:before { content: '\e821'; } /* '' */
.picol_badge_cancel:before { content: '\e822'; } /* '' */
.picol_badge_down:before { content: '\e823'; } /* '' */
.picol_badge_edit:before { content: '\e824'; } /* '' */
.picol_badge_eject:before { content: '\e825'; } /* '' */
.picol_badge_information:before { content: '\e826'; } /* '' */
.picol_badge_minus:before { content: '\e827'; } /* '' */
.picol_badge_pause:before { content: '\e828'; } /* '' */
.picol_badge_plus:before { content: '\e829'; } /* '' */
.picol_badge_run:before { content: '\e82a'; } /* '' */
.picol_badge_security:before { content: '\e82b'; } /* '' */
.picol_badge_settings:before { content: '\e82c'; } /* '' */
.picol_badge_stop:before { content: '\e82d'; } /* '' */
.picol_badge_up:before { content: '\e82e'; } /* '' */
.picol_baseball:before { content: '\e82f'; } /* '' */
.picol_basketball:before { content: '\e830'; } /* '' */
.picol_battery_1:before { content: '\e831'; } /* '' */
.picol_battery_2:before { content: '\e832'; } /* '' */
.picol_battery_3:before { content: '\e833'; } /* '' */
.picol_battery_4:before { content: '\e834'; } /* '' */
.picol_battery_empty:before { content: '\e835'; } /* '' */
.picol_battery_full:before { content: '\e836'; } /* '' */
.picol_battery_plugged:before { content: '\e837'; } /* '' */
.picol_bed:before { content: '\e838'; } /* '' */
.picol_book_audio:before { content: '\e839'; } /* '' */
.picol_book_audio_add:before { content: '\e83a'; } /* '' */
.picol_book_audio_eject:before { content: '\e83b'; } /* '' */
.picol_book_audio_information:before { content: '\e83c'; } /* '' */
.picol_book_audio_pause:before { content: '\e83d'; } /* '' */
.picol_book_audio_remove:before { content: '\e83e'; } /* '' */
.picol_book_audio_run:before { content: '\e83f'; } /* '' */
.picol_book_audio_security:before { content: '\e840'; } /* '' */
.picol_book_audio_settings:before { content: '\e841'; } /* '' */
.picol_book_audio_stop:before { content: '\e842'; } /* '' */
.picol_book_image:before { content: '\e843'; } /* '' */
.picol_book_image_add:before { content: '\e844'; } /* '' */
.picol_book_image_information:before { content: '\e845'; } /* '' */
.picol_book_image_pause:before { content: '\e846'; } /* '' */
.picol_book_image_remove:before { content: '\e847'; } /* '' */
.picol_book_image_run:before { content: '\e848'; } /* '' */
.picol_book_image_security:before { content: '\e849'; } /* '' */
.picol_book_image_settings:before { content: '\e84a'; } /* '' */
.picol_book_image_stop:before { content: '\e84b'; } /* '' */
.picol_bookmark:before { content: '\e84c'; } /* '' */
.picol_bookmark_settings:before { content: '\e84d'; } /* '' */
.picol_book_sans:before { content: '\e84e'; } /* '' */
.picol_book_sans_add:before { content: '\e84f'; } /* '' */
.picol_book_sans_down:before { content: '\e850'; } /* '' */
.picol_book_sans_information:before { content: '\e851'; } /* '' */
.picol_book_sans_remove:before { content: '\e852'; } /* '' */
.picol_book_sans_run:before { content: '\e853'; } /* '' */
.picol_book_sans_security:before { content: '\e854'; } /* '' */
.picol_book_sans_up:before { content: '\e855'; } /* '' */
.picol_book_text:before { content: '\e856'; } /* '' */
.picol_book_text_add:before { content: '\e857'; } /* '' */
.picol_book_text_down:before { content: '\e858'; } /* '' */
.picol_book_text_information:before { content: '\e859'; } /* '' */
.picol_book_text_remove:before { content: '\e85a'; } /* '' */
.picol_book_text_run:before { content: '\e85b'; } /* '' */
.picol_book_text_security:before { content: '\e85c'; } /* '' */
.picol_book_text_settings:before { content: '\e85d'; } /* '' */
.picol_book_text_stop:before { content: '\e85e'; } /* '' */
.picol_book_text_up:before { content: '\e85f'; } /* '' */
.picol_brightness_brighten:before { content: '\e860'; } /* '' */
.picol_brightness_darken:before { content: '\e861'; } /* '' */
.picol_browser_window:before { content: '\e862'; } /* '' */
.picol_browser_window_add:before { content: '\e863'; } /* '' */
.picol_browser_window_cancel:before { content: '\e864'; } /* '' */
.picol_browser_window_remove:before { content: '\e865'; } /* '' */
.picol_browser_window_security:before { content: '\e866'; } /* '' */
.picol_browser_window_settings:before { content: '\e867'; } /* '' */
.picol_buy:before { content: '\e868'; } /* '' */
.picol_calculator:before { content: '\e869'; } /* '' */
.picol_calendar:before { content: '\e86a'; } /* '' */
.picol_cancel:before { content: '\e86b'; } /* '' */
.picol_category:before { content: '\e86c'; } /* '' */
.picol_category_add:before { content: '\e86d'; } /* '' */
.picol_category_edit:before { content: '\e86e'; } /* '' */
.picol_category_remove:before { content: '\e86f'; } /* '' */
.picol_category_settings:before { content: '\e870'; } /* '' */
.picol_cd:before { content: '\e871'; } /* '' */
.picol_cd_eject:before { content: '\e872'; } /* '' */
.picol_cd_pause:before { content: '\e873'; } /* '' */
.picol_cd_run:before { content: '\e874'; } /* '' */
.picol_cd_security:before { content: '\e875'; } /* '' */
.picol_cd_stop:before { content: '\e876'; } /* '' */
.picol_cd_write:before { content: '\e877'; } /* '' */
.picol_chat:before { content: '\e878'; } /* '' */
.picol_chat_pause:before { content: '\e879'; } /* '' */
.picol_chat_run:before { content: '\e87a'; } /* '' */
.picol_chat_security:before { content: '\e87b'; } /* '' */
.picol_chat_settings:before { content: '\e87c'; } /* '' */
.picol_chat_stop:before { content: '\e87d'; } /* '' */
.picol_clock:before { content: '\e87e'; } /* '' */
.picol_clock_mini:before { content: '\e87f'; } /* '' */
.picol_cloud:before { content: '\e880'; } /* '' */
.picol_codec_image:before { content: '\e881'; } /* '' */
.picol_codec_video:before { content: '\e882'; } /* '' */
.picol_combine:before { content: '\e883'; } /* '' */
.picol_comment:before { content: '\e884'; } /* '' */
.picol_comment_accept:before { content: '\e885'; } /* '' */
.picol_comment_add:before { content: '\e886'; } /* '' */
.picol_comment_cancel:before { content: '\e887'; } /* '' */
.picol_comment_edit:before { content: '\e888'; } /* '' */
.picol_comment_remove:before { content: '\e889'; } /* '' */
.picol_comment_settings:before { content: '\e88a'; } /* '' */
.picol_computer:before { content: '\e88b'; } /* '' */
.picol_computer_accept:before { content: '\e88c'; } /* '' */
.picol_computer_add:before { content: '\e88d'; } /* '' */
.picol_computer_cancel:before { content: '\e88e'; } /* '' */
.picol_computer_remove:before { content: '\e88f'; } /* '' */
.picol_computer_settings:before { content: '\e890'; } /* '' */
.picol_controls_chapter_next:before { content: '\e891'; } /* '' */
.picol_controls_chapter_previous:before { content: '\e892'; } /* '' */
.picol_controls_eject:before { content: '\e893'; } /* '' */
.picol_controls_fast_forward:before { content: '\e894'; } /* '' */
.picol_controls_pause:before { content: '\e895'; } /* '' */
.picol_controls_play:before { content: '\e896'; } /* '' */
.picol_controls_play_back:before { content: '\e897'; } /* '' */
.picol_controls_rewind:before { content: '\e898'; } /* '' */
.picol_controls_stop:before { content: '\e899'; } /* '' */
.picol_cooler:before { content: '\e89a'; } /* '' */
.picol_copy:before { content: '\e89b'; } /* '' */
.picol_credit_card:before { content: '\e89c'; } /* '' */
.picol_crown:before { content: '\e89d'; } /* '' */
.picol_cut:before { content: '\e89e'; } /* '' */
.picol_database:before { content: '\e89f'; } /* '' */
.picol_database_add:before { content: '\e8a0'; } /* '' */
.picol_database_edit:before { content: '\e8a1'; } /* '' */
.picol_database_information:before { content: '\e8a2'; } /* '' */
.picol_database_remove:before { content: '\e8a3'; } /* '' */
.picol_database_run:before { content: '\e8a4'; } /* '' */
.picol_database_security:before { content: '\e8a5'; } /* '' */
.picol_data_privacy:before { content: '\e8a6'; } /* '' */
.picol_document_image:before { content: '\e8a7'; } /* '' */
.picol_document_image_accept:before { content: '\e8a8'; } /* '' */
.picol_document_image_add:before { content: '\e8a9'; } /* '' */
.picol_document_image_cancel:before { content: '\e8aa'; } /* '' */
.picol_document_image_down:before { content: '\e8ab'; } /* '' */
.picol_document_image_edit:before { content: '\e8ac'; } /* '' */
.picol_document_image_information:before { content: '\e8ad'; } /* '' */
.picol_document_image_remove:before { content: '\e8ae'; } /* '' */
.picol_document_image_run:before { content: '\e8af'; } /* '' */
.picol_document_image_security:before { content: '\e8b0'; } /* '' */
.picol_document_image_settings:before { content: '\e8b1'; } /* '' */
.picol_document_image_up:before { content: '\e8b2'; } /* '' */
.picol_document_music:before { content: '\e8b3'; } /* '' */
.picol_document_music_accept:before { content: '\e8b4'; } /* '' */
.picol_document_music_add:before { content: '\e8b5'; } /* '' */
.picol_document_music_cancel:before { content: '\e8b6'; } /* '' */
.picol_document_music_down:before { content: '\e8b7'; } /* '' */
.picol_document_music_edit:before { content: '\e8b8'; } /* '' */
.picol_document_music_information:before { content: '\e8b9'; } /* '' */
.picol_document_music_remove:before { content: '\e8ba'; } /* '' */
.picol_document_music_run:before { content: '\e8bb'; } /* '' */
.picol_document_music_security:before { content: '\e8bc'; } /* '' */
.picol_document_music_settings:before { content: '\e8bd'; } /* '' */
.picol_document_music_up:before { content: '\e8be'; } /* '' */
.picol_document_sans:before { content: '\e8bf'; } /* '' */
.picol_document_sans_accept:before { content: '\e8c0'; } /* '' */
.picol_document_sans_add:before { content: '\e8c1'; } /* '' */
.picol_document_sans_cancel:before { content: '\e8c2'; } /* '' */
.picol_document_sans_down:before { content: '\e8c3'; } /* '' */
.picol_document_sans_edit:before { content: '\e8c4'; } /* '' */
.picol_document_sans_information:before { content: '\e8c5'; } /* '' */
.picol_document_sans_remove:before { content: '\e8c6'; } /* '' */
.picol_document_sans_run:before { content: '\e8c7'; } /* '' */
.picol_document_sans_security:before { content: '\e8c8'; } /* '' */
.picol_document_sans_settings:before { content: '\e8c9'; } /* '' */
.picol_document_sans_up:before { content: '\e8ca'; } /* '' */
.picol_document_text:before { content: '\e8cb'; } /* '' */
.picol_document_text_accept:before { content: '\e8cc'; } /* '' */
.picol_document_text_add:before { content: '\e8cd'; } /* '' */
.picol_document_text_cancel:before { content: '\e8ce'; } /* '' */
.picol_document_text_down:before { content: '\e8cf'; } /* '' */
.picol_document_text_edit:before { content: '\e8d0'; } /* '' */
.picol_document_text_information:before { content: '\e8d1'; } /* '' */
.picol_document_text_remove:before { content: '\e8d2'; } /* '' */
.picol_document_text_run:before { content: '\e8d3'; } /* '' */
.picol_document_text_security:before { content: '\e8d4'; } /* '' */
.picol_document_text_settings:before { content: '\e8d5'; } /* '' */
.picol_document_text_up:before { content: '\e8d6'; } /* '' */
.picol_document_video:before { content: '\e8d7'; } /* '' */
.picol_document_video_accept:before { content: '\e8d8'; } /* '' */
.picol_document_video_add:before { content: '\e8d9'; } /* '' */
.picol_document_video_cancel:before { content: '\e8da'; } /* '' */
.picol_document_video_down:before { content: '\e8db'; } /* '' */
.picol_document_video_edit:before { content: '\e8dc'; } /* '' */
.picol_document_video_information:before { content: '\e8dd'; } /* '' */
.picol_document_video_remove:before { content: '\e8de'; } /* '' */
.picol_document_video_run:before { content: '\e8df'; } /* '' */
.picol_document_video_security:before { content: '\e8e0'; } /* '' */
.picol_document_video_settings:before { content: '\e8e1'; } /* '' */
.picol_document_video_up:before { content: '\e8e2'; } /* '' */
.picol_donate:before { content: '\e8e3'; } /* '' */
.picol_download:before { content: '\e8e4'; } /* '' */
.picol_download_accept:before { content: '\e8e5'; } /* '' */
.picol_download_cancel:before { content: '\e8e6'; } /* '' */
.picol_download_information:before { content: '\e8e7'; } /* '' */
.picol_download_pause:before { content: '\e8e8'; } /* '' */
.picol_download_run:before { content: '\e8e9'; } /* '' */
.picol_download_security:before { content: '\e8ea'; } /* '' */
.picol_download_settings:before { content: '\e8eb'; } /* '' */
.picol_download_stop:before { content: '\e8ec'; } /* '' */
.picol_dropbox:before { content: '\e8ed'; } /* '' */
.picol_edit:before { content: '\e8ee'; } /* '' */
.picol_entrance:before { content: '\e8ef'; } /* '' */
.picol_equal:before { content: '\e8f0'; } /* '' */
.picol_filepath:before { content: '\e8f1'; } /* '' */
.picol_filter:before { content: '\e8f2'; } /* '' */
.picol_filter_settings:before { content: '\e8f3'; } /* '' */
.picol_firewall:before { content: '\e8f4'; } /* '' */
.picol_firewall_pause:before { content: '\e8f5'; } /* '' */
.picol_firewall_run:before { content: '\e8f6'; } /* '' */
.picol_firewall_settings:before { content: '\e8f7'; } /* '' */
.picol_firewall_stop:before { content: '\e8f8'; } /* '' */
.picol_flag:before { content: '\e8f9'; } /* '' */
.picol_flash:before { content: '\e8fa'; } /* '' */
.picol_flash_off:before { content: '\e8fb'; } /* '' */
.picol_floppy_disk:before { content: '\e8fc'; } /* '' */
.picol_folder_downloads:before { content: '\e8fd'; } /* '' */
.picol_folder_image:before { content: '\e8fe'; } /* '' */
.picol_folder_music:before { content: '\e8ff'; } /* '' */
.picol_folder_sans:before { content: '\e900'; } /* '' */
.picol_folder_sans_accept:before { content: '\e901'; } /* '' */
.picol_folder_sans_add:before { content: '\e902'; } /* '' */
.picol_folder_sans_cancel:before { content: '\e903'; } /* '' */
.picol_folder_sans_down:before { content: '\e904'; } /* '' */
.picol_folder_sans_edit:before { content: '\e905'; } /* '' */
.picol_folder_sans_information:before { content: '\e906'; } /* '' */
.picol_folder_sans_remove:before { content: '\e907'; } /* '' */
.picol_folder_sans_run:before { content: '\e908'; } /* '' */
.picol_folder_sans_security:before { content: '\e909'; } /* '' */
.picol_folder_sans_settings:before { content: '\e90a'; } /* '' */
.picol_folder_sans_up:before { content: '\e90b'; } /* '' */
.picol_folder_text:before { content: '\e90c'; } /* '' */
.picol_folder_video:before { content: '\e90d'; } /* '' */
.picol_football:before { content: '\e90e'; } /* '' */
.picol_fullscreen:before { content: '\e90f'; } /* '' */
.picol_fullscreen_cancel:before { content: '\e910'; } /* '' */
.picol_game_controller:before { content: '\e911'; } /* '' */
.picol_adressbook:before { content: '\e801'; } /* '' */
.picol_golf:before { content: '\e913'; } /* '' */
.picol_group_full:before { content: '\e914'; } /* '' */
.picol_group_full_add:before { content: '\e915'; } /* '' */
.picol_group_full_edit:before { content: '\e916'; } /* '' */
.picol_group_full_remove:before { content: '\e917'; } /* '' */
.picol_group_full_security:before { content: '\e918'; } /* '' */
.picol_group_half:before { content: '\e919'; } /* '' */
.picol_group_half_add:before { content: '\e91a'; } /* '' */
.picol_group_half_edit:before { content: '\e91b'; } /* '' */
.picol_group_half_remove:before { content: '\e91c'; } /* '' */
.picol_group_half_security:before { content: '\e91d'; } /* '' */
.picol_harddisk_sans:before { content: '\e91e'; } /* '' */
.picol_harddisk_sans_eject:before { content: '\e91f'; } /* '' */
.picol_harddisk_sans_security:before { content: '\e920'; } /* '' */
.picol_harddisk_sans_settings:before { content: '\e921'; } /* '' */
.picol_hierarchy:before { content: '\e922'; } /* '' */
.picol_home:before { content: '\e923'; } /* '' */
.picol_image:before { content: '\e924'; } /* '' */
.picol_image_accept:before { content: '\e925'; } /* '' */
.picol_image_add:before { content: '\e926'; } /* '' */
.picol_image_cancel:before { content: '\e927'; } /* '' */
.picol_image_edit:before { content: '\e928'; } /* '' */
.picol_image_pause:before { content: '\e929'; } /* '' */
.picol_image_remove:before { content: '\e92a'; } /* '' */
.picol_image_run:before { content: '\e92b'; } /* '' */
.picol_image_security:before { content: '\e92c'; } /* '' */
.picol_image_settings:before { content: '\e92d'; } /* '' */
.picol_imprint:before { content: '\e92e'; } /* '' */
.picol_information:before { content: '\e92f'; } /* '' */
.picol_internet:before { content: '\e930'; } /* '' */
.picol_keyboard:before { content: '\e931'; } /* '' */
.picol_label:before { content: '\e932'; } /* '' */
.picol_label_add:before { content: '\e933'; } /* '' */
.picol_label_edit:before { content: '\e934'; } /* '' */
.picol_label_remove:before { content: '\e935'; } /* '' */
.picol_label_security:before { content: '\e936'; } /* '' */
.picol_leaf:before { content: '\e937'; } /* '' */
.picol_light:before { content: '\e938'; } /* '' */
.picol_light_off:before { content: '\e939'; } /* '' */
.picol_link:before { content: '\e93a'; } /* '' */
.picol_link_add:before { content: '\e93b'; } /* '' */
.picol_link_edit:before { content: '\e93c'; } /* '' */
.picol_link_remove:before { content: '\e93d'; } /* '' */
.picol_list:before { content: '\e93e'; } /* '' */
.picol_list_numbered:before { content: '\e93f'; } /* '' */
.picol_login:before { content: '\e940'; } /* '' */
.picol_logout:before { content: '\e941'; } /* '' */
.picol_mail:before { content: '\e942'; } /* '' */
.picol_mail_accept:before { content: '\e943'; } /* '' */
.picol_mail_add:before { content: '\e944'; } /* '' */
.picol_mailbox:before { content: '\e945'; } /* '' */
.picol_mailbox_down:before { content: '\e946'; } /* '' */
.picol_mailbox_eject:before { content: '\e947'; } /* '' */
.picol_mailbox_incoming:before { content: '\e948'; } /* '' */
.picol_mailbox_outgoing:before { content: '\e949'; } /* '' */
.picol_mailbox_settings:before { content: '\e94a'; } /* '' */
.picol_mail_cancel:before { content: '\e94b'; } /* '' */
.picol_mail_edit:before { content: '\e94c'; } /* '' */
.picol_mail_fwd:before { content: '\e94d'; } /* '' */
.picol_mail_remove:before { content: '\e94e'; } /* '' */
.picol_mail_run:before { content: '\e94f'; } /* '' */
.picol_mail_security:before { content: '\e950'; } /* '' */
.picol_mainframe:before { content: '\e951'; } /* '' */
.picol_mashup:before { content: '\e952'; } /* '' */
.picol_mobile_phone:before { content: '\e953'; } /* '' */
.picol_move:before { content: '\e954'; } /* '' */
.picol_music:before { content: '\e955'; } /* '' */
.picol_music_accept:before { content: '\e956'; } /* '' */
.picol_music_add:before { content: '\e957'; } /* '' */
.picol_music_cancel:before { content: '\e958'; } /* '' */
.picol_music_edit:before { content: '\e959'; } /* '' */
.picol_music_eject:before { content: '\e95a'; } /* '' */
.picol_music_information:before { content: '\e95b'; } /* '' */
.picol_music_pause:before { content: '\e95c'; } /* '' */
.picol_music_remove:before { content: '\e95d'; } /* '' */
.picol_music_run:before { content: '\e95e'; } /* '' */
.picol_music_security:before { content: '\e95f'; } /* '' */
.picol_music_settings:before { content: '\e960'; } /* '' */
.picol_music_stop:before { content: '\e961'; } /* '' */
.picol_network_intranet:before { content: '\e962'; } /* '' */
.picol_network_protocol:before { content: '\e963'; } /* '' */
.picol_network_sans:before { content: '\e964'; } /* '' */
.picol_network_sans_add:before { content: '\e965'; } /* '' */
.picol_network_sans_edit:before { content: '\e966'; } /* '' */
.picol_network_sans_remove:before { content: '\e967'; } /* '' */
.picol_network_sans_security:before { content: '\e968'; } /* '' */
.picol_network_wireless:before { content: '\e969'; } /* '' */
.picol_network_wireless_add:before { content: '\e96a'; } /* '' */
.picol_network_wireless_edit:before { content: '\e96b'; } /* '' */
.picol_network_wireless_security:before { content: '\e96c'; } /* '' */
.picol_news:before { content: '\e96d'; } /* '' */
.picol_notes:before { content: '\e96e'; } /* '' */
.picol_notes_accept:before { content: '\e96f'; } /* '' */
.picol_notes_add:before { content: '\e970'; } /* '' */
.picol_notes_cancel:before { content: '\e971'; } /* '' */
.picol_notes_down:before { content: '\e972'; } /* '' */
.picol_notes_edit:before { content: '\e973'; } /* '' */
.picol_notes_remove:before { content: '\e974'; } /* '' */
.picol_notes_settings:before { content: '\e975'; } /* '' */
.picol_notes_up:before { content: '\e976'; } /* '' */
.picol_ontology:before { content: '\e977'; } /* '' */
.picol_owl_dl:before { content: '\e978'; } /* '' */
.picol_owl_dl_document:before { content: '\e979'; } /* '' */
.picol_owl_full:before { content: '\e97a'; } /* '' */
.picol_owl_full_document:before { content: '\e97b'; } /* '' */
.picol_owl_lite:before { content: '\e97c'; } /* '' */
.picol_owl_lite_document:before { content: '\e97d'; } /* '' */
.picol_paragraph:before { content: '\e97e'; } /* '' */
.picol_paste:before { content: '\e97f'; } /* '' */
.picol_path:before { content: '\e980'; } /* '' */
.picol_pda:before { content: '\e981'; } /* '' */
.picol_pear:before { content: '\e982'; } /* '' */
.picol_phone_home:before { content: '\e983'; } /* '' */
.picol_phone_off:before { content: '\e984'; } /* '' */
.picol_phone_on:before { content: '\e985'; } /* '' */
.picol_pin:before { content: '\e986'; } /* '' */
.picol_pin_filled:before { content: '\e987'; } /* '' */
.picol_plus:before { content: '\e988'; } /* '' */
.picol_point_of_interest:before { content: '\e989'; } /* '' */
.picol_printer:before { content: '\e98a'; } /* '' */
.picol_printer_add:before { content: '\e98b'; } /* '' */
.picol_printer_cancel:before { content: '\e98c'; } /* '' */
.picol_printer_information:before { content: '\e98d'; } /* '' */
.picol_printer_pause:before { content: '\e98e'; } /* '' */
.picol_printer_remove:before { content: '\e98f'; } /* '' */
.picol_printer_run:before { content: '\e990'; } /* '' */
.picol_printer_settings:before { content: '\e991'; } /* '' */
.picol_printer_stop:before { content: '\e992'; } /* '' */
.picol_questionmark:before { content: '\e993'; } /* '' */
.picol_rdf:before { content: '\e994'; } /* '' */
.picol_rdf_document:before { content: '\e995'; } /* '' */
.picol_recent_changes:before { content: '\e996'; } /* '' */
.picol_refresh:before { content: '\e997'; } /* '' */
.picol_relevance:before { content: '\e998'; } /* '' */
.picol_remix:before { content: '\e999'; } /* '' */
.picol_route:before { content: '\e99a'; } /* '' */
.picol_satellite:before { content: '\e99b'; } /* '' */
.picol_satellite_ground:before { content: '\e99c'; } /* '' */
.picol_screen_4to3:before { content: '\e99d'; } /* '' */
.picol_screen_16to9:before { content: '\e99e'; } /* '' */
.picol_script:before { content: '\e99f'; } /* '' */
.picol_search:before { content: '\e9a0'; } /* '' */
.picol_security_closed:before { content: '\e9a1'; } /* '' */
.picol_security_open:before { content: '\e9a2'; } /* '' */
.picol_semantic_web:before { content: '\e9a3'; } /* '' */
.picol_server:before { content: '\e9a4'; } /* '' */
.picol_server_accept:before { content: '\e9a5'; } /* '' */
.picol_server_add:before { content: '\e9a6'; } /* '' */
.picol_server_cancel:before { content: '\e9a7'; } /* '' */
.picol_server_edit:before { content: '\e9a8'; } /* '' */
.picol_server_eject:before { content: '\e9a9'; } /* '' */
.picol_server_information:before { content: '\e9aa'; } /* '' */
.picol_server_remove:before { content: '\e9ab'; } /* '' */
.picol_server_run:before { content: '\e9ac'; } /* '' */
.picol_server_security:before { content: '\e9ad'; } /* '' */
.picol_server_settings:before { content: '\e9ae'; } /* '' */
.picol_server_stop:before { content: '\e9af'; } /* '' */
.picol_settings:before { content: '\e9b0'; } /* '' */
.picol_shopping_cart:before { content: '\e9b1'; } /* '' */
.picol_sitemap:before { content: '\e9b2'; } /* '' */
.picol_size_both:before { content: '\e9b3'; } /* '' */
.picol_size_both_accept:before { content: '\e9b4'; } /* '' */
.picol_size_both_add:before { content: '\e9b5'; } /* '' */
.picol_size_both_cancel:before { content: '\e9b6'; } /* '' */
.picol_size_both_edit:before { content: '\e9b7'; } /* '' */
.picol_size_both_remove:before { content: '\e9b8'; } /* '' */
.picol_size_both_security:before { content: '\e9b9'; } /* '' */
.picol_size_both_settings:before { content: '\e9ba'; } /* '' */
.picol_size_height:before { content: '\e9bb'; } /* '' */
.picol_size_height_accept:before { content: '\e9bc'; } /* '' */
.picol_size_height_add:before { content: '\e9bd'; } /* '' */
.picol_size_height_cancel:before { content: '\e9be'; } /* '' */
.picol_size_height_edit:before { content: '\e9bf'; } /* '' */
.picol_size_height_remove:before { content: '\e9c0'; } /* '' */
.picol_size_height_security:before { content: '\e9c1'; } /* '' */
.picol_size_height_settings:before { content: '\e9c2'; } /* '' */
.picol_size_width:before { content: '\e9c3'; } /* '' */
.picol_size_width_accept:before { content: '\e9c4'; } /* '' */
.picol_size_width_add:before { content: '\e9c5'; } /* '' */
.picol_size_width_cancel:before { content: '\e9c6'; } /* '' */
.picol_size_width_edit:before { content: '\e9c7'; } /* '' */
.picol_size_width_remove:before { content: '\e9c8'; } /* '' */
.picol_size_width_security:before { content: '\e9c9'; } /* '' */
.picol_size_width_settings:before { content: '\e9ca'; } /* '' */
.picol_soccer:before { content: '\e9cb'; } /* '' */
.picol_social_network:before { content: '\e9cc'; } /* '' */
.picol_source_code:before { content: '\e9cd'; } /* '' */
.picol_speaker_louder:before { content: '\e9ce'; } /* '' */
.picol_speaker_off:before { content: '\e9cf'; } /* '' */
.picol_speaker_silent:before { content: '\e9d0'; } /* '' */
.picol_star:before { content: '\e9d1'; } /* '' */
.picol_star_outline:before { content: '\e9d2'; } /* '' */
.picol_statistics:before { content: '\e9d3'; } /* '' */
.picol_synchronize:before { content: '\e9d4'; } /* '' */
.picol_tab:before { content: '\e9d5'; } /* '' */
.picol_tab_add:before { content: '\e9d6'; } /* '' */
.picol_tab_cancel:before { content: '\e9d7'; } /* '' */
.picol_tablet:before { content: '\e9d8'; } /* '' */
.picol_target:before { content: '\e9d9'; } /* '' */
.picol_terminal_computer:before { content: '\e9da'; } /* '' */
.picol_text:before { content: '\e9db'; } /* '' */
.picol_text_align_center:before { content: '\e9dc'; } /* '' */
.picol_text_align_full:before { content: '\e9dd'; } /* '' */
.picol_text_align_left:before { content: '\e9de'; } /* '' */
.picol_text_align_right:before { content: '\e9df'; } /* '' */
.picol_text_bold:before { content: '\e9e0'; } /* '' */
.picol_text_italic:before { content: '\e9e1'; } /* '' */
.picol_text_strikethrough:before { content: '\e9e2'; } /* '' */
.picol_transportation_bus:before { content: '\e9e3'; } /* '' */
.picol_transportation_car:before { content: '\e9e4'; } /* '' */
.picol_transportation_plane:before { content: '\e9e5'; } /* '' */
.picol_transportation_ship:before { content: '\e9e6'; } /* '' */
.picol_transportation_train:before { content: '\e9e7'; } /* '' */
.picol_transportation_truck:before { content: '\e9e8'; } /* '' */
.picol_trash:before { content: '\e9e9'; } /* '' */
.picol_trash_full:before { content: '\e9ea'; } /* '' */
.picol_tree:before { content: '\e9eb'; } /* '' */
.picol_upload:before { content: '\e9ec'; } /* '' */
.picol_upload_accept:before { content: '\e9ed'; } /* '' */
.picol_upload_cancel:before { content: '\e9ee'; } /* '' */
.picol_upload_pause:before { content: '\e9ef'; } /* '' */
.picol_upload_run:before { content: '\e9f0'; } /* '' */
.picol_upload_security:before { content: '\e9f1'; } /* '' */
.picol_upload_settings:before { content: '\e9f2'; } /* '' */
.picol_upload_stop:before { content: '\e9f3'; } /* '' */
.picol_user_close:before { content: '\e9f4'; } /* '' */
.picol_user_close_add:before { content: '\e9f5'; } /* '' */
.picol_user_close_edit:before { content: '\e9f6'; } /* '' */
.picol_user_close_information:before { content: '\e9f7'; } /* '' */
.picol_user_close_remove:before { content: '\e9f8'; } /* '' */
.picol_user_close_security:before { content: '\e9f9'; } /* '' */
.picol_user_close_settings:before { content: '\e9fa'; } /* '' */
.picol_user_full:before { content: '\e9fb'; } /* '' */
.picol_user_full_add:before { content: '\e9fc'; } /* '' */
.picol_user_full_edit:before { content: '\e9fd'; } /* '' */
.picol_user_full_information:before { content: '\e9fe'; } /* '' */
.picol_user_full_remove:before { content: '\e9ff'; } /* '' */
.picol_user_full_security:before { content: '\ea00'; } /* '' */
.picol_user_full_settings:before { content: '\ea01'; } /* '' */
.picol_user_half:before { content: '\ea02'; } /* '' */
.picol_user_half_add:before { content: '\ea03'; } /* '' */
.picol_user_half_edit:before { content: '\ea04'; } /* '' */
.picol_user_half_information:before { content: '\ea05'; } /* '' */
.picol_user_half_remove:before { content: '\ea06'; } /* '' */
.picol_user_half_security:before { content: '\ea07'; } /* '' */
.picol_user_half_settings:before { content: '\ea08'; } /* '' */
.picol_user_profile:before { content: '\ea09'; } /* '' */
.picol_user_profile_add:before { content: '\ea0a'; } /* '' */
.picol_user_profile_edit:before { content: '\ea0b'; } /* '' */
.picol_video:before { content: '\ea0c'; } /* '' */
.picol_video_accept:before { content: '\ea0d'; } /* '' */
.picol_video_add:before { content: '\ea0e'; } /* '' */
.picol_video_cancel:before { content: '\ea0f'; } /* '' */
.picol_video_down:before { content: '\ea10'; } /* '' */
.picol_video_edit:before { content: '\ea11'; } /* '' */
.picol_video_information:before { content: '\ea12'; } /* '' */
.picol_video_pause:before { content: '\ea13'; } /* '' */
.picol_video_remove:before { content: '\ea14'; } /* '' */
.picol_video_run:before { content: '\ea15'; } /* '' */
.picol_video_security:before { content: '\ea16'; } /* '' */
.picol_video_settings:before { content: '\ea17'; } /* '' */
.picol_video_stop:before { content: '\ea18'; } /* '' */
.picol_video_up:before { content: '\ea19'; } /* '' */
.picol_view:before { content: '\ea1a'; } /* '' */
.picol_viewer_image:before { content: '\ea1b'; } /* '' */
.picol_viewer_text:before { content: '\ea1c'; } /* '' */
.picol_viewer_video:before { content: '\ea1d'; } /* '' */
.picol_vuvuzela:before { content: '\ea1e'; } /* '' */
.picol_website:before { content: '\ea1f'; } /* '' */
.picol_weight:before { content: '\ea20'; } /* '' */
.picol_xml:before { content: '\ea21'; } /* '' */
.picol_xml_document:before { content: '\ea22'; } /* '' */
.picol_zoom_in:before { content: '\ea23'; } /* '' */
.picol_globe:before { content: '\e912'; } /* '' */