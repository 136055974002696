
/* This file is for setting global styles  */
$font-size-base: 0.9rem;
$line-height-base: 1.4;

@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';

@import "bootstrap/scss/bootstrap";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import './assets/toastr-bs4-alert.scss';
@import "~intl-tel-input/build/css/intlTelInput.css";

@import "./assets/ckeditor/css/picol.css";
@import "./assets/ckeditor/decoupled.css";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.bs-callout {
    padding: 5px 10px 10px 10px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
  }
  
  .bs-callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .bs-callout p:last-child {
    margin-bottom: 0;
  }
  
  .bs-callout code {
    border-radius: 3px;
  }
  
  .bs-callout+.bs-callout {
    margin-top: -5px;
  }
  
  .bs-callout-default {
    border-left-color: #777;
  }
  
  .bs-callout-default h4 {
    color: #777;
  }
  
  .bs-callout-right {
    padding: 5px 10px 10px 10px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-right-width: 5px;
    border-radius: 3px;
  }
  
  .bs-callout-default-right {
    border-right-color: #777;
  }
  
  .bs-callout-right h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .bs-callout-right p:last-child {
    margin-bottom: 0;
  }
  
  .bs-callout-right code {
    border-radius: 3px;
  }
  
  .bs-callout-right+.bs-callout-right {
    margin-top: -5px;
  }
  
  .bs-callout-default-right h4 {
    color: #777;
  }
  
  nav {
    margin-top: 16px;
  }
  
  body {
    font-family: Arial, Helvetica, sans-serif;
  }
  // Media Query For Mobile
  @media only screen and (max-width: 600px) {
   .mobile-padding-top{
     padding-top: 0.5rem;
   }
   .mobile-padding-menu{
     padding: 0.5rem !important;
   }
  }
  @media only screen and (max-width: 768px) {
    .tab-padding-menu{
      padding: 0.5rem !important;
    }
    .tab-margin-top-5 {
      margin-top: 5px;
    }
   }
  
  .cke_dialog_contents {
    min-width: 400px !important;
  }
  
  .img-holder img {
    max-width: 100% !important;
  }
  .image-hide img {
    display: none !important;
  }
  
  .image-hide div {
    color: #737373 !important;
  }
  
  .ui-widget {
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: inherit !important;
  }
  
  .cke_editable {
    overflow-y: scroll !important;
    /* has to be scroll,not auto */
    -webkit-overflow-scrolling: touch !important;
  }
  
  .table>thead>tr>th,
  .table>tbody>tr>th,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>tbody>tr>td,
  .table>tfoot>tr>td {
    font-size: 12px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }
  
  .label-info {
    white-space: normal !important;
  }
  
  .z-index-9999 {
    z-index: 9999 !important;
  }
  .styles {
    border-color: #53baf2;
    padding: 5px;
    color: #93a4b1;
    width: 125px;
  }
  
  .z-index-9999 {
    z-index: 99999 !important;
  }
  
  .text-truncate-5 {
    max-height: 115px;
    height: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  
  .white-space-pre-wrap {
    white-space: pre-wrap !important;
  }
  
  .star {
    position: relative;
    display: inline-block;
    font-size: 30px;
    color: #d3d3d3;
  }
  
  .full {
    font-size: 30px;
    color: gold;
  }
  
  .half {
    font-size: 30px;
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: gold;
  }
  
  .font-14 {
    font-size: 14px;
  }
  
  .font-16 {
    font-size: 16px;
  }
  
  .bold {
    font-weight: bold;
  }
  .errormessage.ui-message {
    margin: 6px .25em;
  }
  .height-30{
    height:30px;
  }
  
  .paymntCalendr .ui-calendar, .paymntCalendr .ui-inputtext {
    width:100%;
  }
  
  //Remove Ugly outline on rating
  ngb-rating:focus {
    outline: none;
  }
  
  .add-spinner {
    -moz-appearance: spinner !important;
    -webkit-appearance: initial !important;
  }
  
  // Animation for Progress bar
  .progress-striped.active .progress-bar {
    -webkit-animation: progress-bar-stripes 2s linear infinite !important;
    -o-animation: progress-bar-stripes 2s linear infinite !important;
    animation: progress-bar-stripes 2s linear infinite !important;
  }
  
  .progress-bar.bg-success.progress-bar-animated.progress-bar-striped {
    background-color: #28a745 !important;
  }
  
  .text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 160px; // @TODO Change Style Later
  }
  
  .article-title-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .break-word {
    word-break: break-word;
  }
  
  .align-justify {
    text-align: justify;
  }
  
  .padding-10 {
    padding: 10px;
  }
  .padding-left-10 {
    padding-left: 10px;
  }
  
  .margin-5 {
    margin: 5px 0;
  }
  .padding-3-top {
    padding-top: 3%
  }
  .margin-5-left {
    margin-left: 5%
  }
  .margin-2-left {
    margin-left: 2%
  }
  .margin-left-5{
    margin-left: 5px;
  }
  .margin-5-right {
    margin-right: 5%
  }
  .margin-2-right {
    margin-right: 2%
  }
  .margin-bottom-15 {
    margin-bottom: 15px !important;
  }
  .margin-bottom-10 {
    margin-bottom: 10px !important;
  }
  .overflow-hidden-break-word {
    overflow-wrap: break-word;
    overflow: hidden;
  }
  
  .ui-widget-content a {
    color: #02baf2;
  }
  
  // Common style attributes
  .padding-0 {
    padding: 0;
  }
  
  .padding-8 {
    padding: 8px !important;
  }
  
  .padding-bottom-25 {
    padding-bottom: 25px;
  }
  
  .margin-top-30 {
    margin-top: 30px;
  }
  
  .margin-8 {
    margin: 8px;
  }
  
  .width-10 {
    width: 10% !important;
  }
  
  .font-10 {
    font-size: 10px;
  }
  .font-12 {
    font-size: 12px;
  }
  
  .height-auto {
    height: auto !important;
  }
  
  .outline-none {
    // To remove ngbrating outline 
    outline: none !important;
  }
  
  .float-right {
    float: right;
  }
  
  .word-wrap-word-break {
    word-wrap: normal;
    word-break: break-all;
  }
  
  .wordbreak-break-word {
    word-break: break-word;
  }
  
  .word-break-overflow-wrap {
    word-break: break-all;
    overflow-wrap: break-word;
  }
  
  .panel-dropdown-font-size {
    font-size: 14px;
  }
  
  .accordin-table-overflow {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .accordin-table-font-width {
    font-size: 10pt;
    max-width: 100%;
  }
  
  .accordin-file-list-icon {
    color: #02baf2 !important;
    word-break: break-all;
    width: 100%;
    display: block;
  }
  
  .accordin-file-list-name {
    color: #02baf2 !important;
    word-wrap: normal;
    word-break: break-all;
  }
  
  .comment-overflow {
    height: 100%;
    max-height: 401px;
    overflow-y: auto;
  }
  
  .maxwidth-border-0 {
    max-width: 100%;
    width: 100%;
    border: 0px
  }
  
  .numberBG {
    padding-right: 5px;
    background: #e8e8e8;
    min-width: 55px;
    text-align: right;
  }
  
  .pad-5-10 {
    padding: 5px 10px;
  }
  
  .px-12 {
    padding: 0 12px;
  }
  
  .mt-minus2 {
    margin-top: -2px;
  }
  
  .line-highlight-colour {
    color: #337ab7;
    background-color: #fff999;
  }
  .line-content-highlight-colour
  {
    background-color: #fff999;
  }
  .selected-class {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border-right: 1px solid rgba(81, 203, 238, 1);
    border-bottom: 1px solid rgba(81, 203, 238, 1);
    border-top: 1px solid rgba(81, 203, 238, 1);
  }
  
  .displayTablecell {
    display: table-cell;
  }
  .height-500 {
    height: 500px;
  }
  .displayTable {
    display: table;
    margin-bottom: 0px;
  }
  
  .old-comment-bg {
    background: #efefef;
  }
  .g-signin2{
    width: 100%;
  }
  
  .g-signin2 > div{
    margin: 0 auto;
  }
  
  .edj-card-primary {
    // color: #009dcd;
    border-color: rgba(2, 186, 242, 0.2);
  }
  .bg-edj{
    background-color: rgba(2, 186, 242, 0.2);
  }
  .btn-purple {
    color: #ffffff;
    background-color: #a88cd5;
  }
  .btn-purple:hover,
  .btn-purple:focus,
  .btn-purple:active,
  .btn-purple.active,
  .btn-purple.hover {
    color: #ffffff;
    background-color: #b9a3dd;
    border-color: #b9a3dd;
  }
  .btn-purple:active,
  .btn-purple.active {
    background: #93a4aa;
    border-color: #93a4aa;
  }
  .btn-purple.disabled,
  .btn-purple[disabled],
  fieldset[disabled] .btn-purple,
  .btn-purple.disabled:hover,
  .btn-purple[disabled]:hover,
  fieldset[disabled] .btn-purple:hover,
  .btn-purple.disabled:focus,
  .btn-purple[disabled]:focus,
  fieldset[disabled] .btn-purple:focus,
  .btn-purple.disabled:active,
  .btn-purple[disabled]:active,
  fieldset[disabled] .btn-purple:active,
  .btn-purple.disabled.active,
  .btn-purple[disabled].active,
  fieldset[disabled] .btn-purple.active {
    background-color: #e6edef;
    border-color: #e6edef;
  }
  .btn-orange {
    color: #ffffff;
    background-color: #f58410;
  }
  .text-overflow-ellipsis{
    text-overflow: ellipsis
  }
  .overflow-hidden{
    overflow: hidden;
  }
  
  
  body .ui-accordion .ui-accordion-content {
    // global overflow scroll
    overflow: auto;
  }
  .pointer
   {cursor 
    : pointer;}
    
    .ckeditorMain{
      width: 70% !important;
    }
    .badge-info {
      color: #fff;
      background-color: #17a2b8;
    }
    
    .badge {
      display: inline-block;
      padding: 0.25em 0.4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }
  .font-Italic {
      font-style: italic;
  }
  .questionColor {
      color: gray;
  }
  .w-350 {
    width: 350px;
  }
  .feedbackOverflow{
    width:max-content;overflow: hidden;
  }
  .ui-messages-error {
    color: #ab1a0f;
    background-color: #ffcbc8;
    border-color: #ffcbc8;
    margin : 5px 0 !important; 
  }
  
  .ui-message {
  border: 1px solid;
  margin: 0 .25em;
  padding: .25em .5em;
  display: inline-block;
  }
  @supports (-ms-ime-align: auto) {
    .feedbackOverflow {
      width:auto;
       white-space: nowrap; 
       display : inline-block;}
   }
   .accordion-toggle[aria-expanded="true"]:before {
    content: "\f107";
    font: normal normal normal 14px/1 FontAwesome;
    color: #fff;
  }
  
  .accordion-toggle[aria-expanded="false"]:before {
    content: '\f105';
    font: normal normal normal 14px/1 FontAwesome;
  }
  body .ui-button.ui-button-text-icon-left .ui-button-text {
    padding: 0.429em 1em 0.429em 2.4em;
  }
  
   
    .card-header.panel-enabled {
        padding: 3px 12px;
          button {
           color: #000;
           width: calc(100% - 10px);
          text-align: left;
     .badge {
      top: 2px;
      }
    }
   }
  .panel-open {
    .card-header.panel-enabled {
      background-color: #259ade;
      button {
      color: #fff;
      }
    }
  }
  .mwidth40 {
    max-width: 40%;
  }
  .mwidth48 {
    max-width: 48%;
  }
  .mw95 {
    max-width: 95% !important;
  }
  .mw40 {
    max-width: 40% !important;
  }
  .mw-500{
    max-width: 500px !important;
  }
  .feedback .colwidth-slno{
    max-width: 3%;
  }
  .feedback .colwidth-headings {
    max-width: 6%;
  }
  .angular-editor-textarea{
    margin-top: 0px !important;
  }
  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .padding-7-5{
    padding: 7px 5px;
    }
    .height-35{
    height: 35px;
    }
    .border-grey{
    border: 1px solid #ddd;
    }
    .no-drop {
      cursor: no-drop;
  }
      .angular-editor-toolbar-set {
      display: none !important;
      }
      .angular-editor-toolbar-set ~ .angular-editor-toolbar-set {
        display: inline-block !important;
      }
      .angular-editor-toolbar-set ~ .angular-editor-toolbar-set ~ 
      .angular-editor-toolbar-set
       {
        display: none !important;
      }
      .angular-editor-toolbar-set ~ .angular-editor-toolbar-set ~ 
      .angular-editor-toolbar-set ~ 
      .angular-editor-toolbar-set ~ 
      .angular-editor-toolbar-set {
        display: inline-block !important;
      }
      .angular-editor-toolbar-set ~ .angular-editor-toolbar-set ~ 
      .angular-editor-toolbar-set ~
      .angular-editor-toolbar-set ~
      .angular-editor-toolbar-set ~
      .angular-editor-toolbar-set{
        display: none !important;
      }
      .ck.ck-dropdown.edtw-download-dropdown .ck.ck-button__label {
              width: auto !important;
          }
      .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
        width: auto !important;
     }
     
     .ck.ck-dropdown.ck-heading-dropdown .ck-button.ck-dropdown__button .ck-button__label {
         width: auto !important;
     }
     .ckcard ~ .ck .ck-content {
      position: relative !important;
      /* removed below css becuse of blockqoutes issue bug 3148 - Submit Article - CK editor - Block Quote not working
      /* display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; */
      min-width: 0 !important;
      height: 270px !important;
      word-wrap: break-word !important;
      background-color: #fff !important;
      background-clip: border-box !important;
      border: 1px solid rgba(0,0,0,.125) !important;
      border-radius: .25rem !important;
  }
  .ml-auto,.mx-auto {
    margin-left: auto!important;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none
}

.breadcrumb-item.active {
  color: #6c757d
}
.form-group {
  margin-bottom: 1rem;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.ui-md-2 {
    width: 16.6667%;
}
.pagination {
  justify-content: center !important; 

}
.ui-md-9 {
  width: 75% !important;
}
.text-right {
  text-align: right !important;
}
.display_non {
  display: none;
}
.input-group-append, .input-group-prepend {
  display: flex !important;
}
.ml-1, .mx-1 {
  margin-left: 0.25rem!important;
}
.ml-2, .mx-1 {
  margin-left: 0.50rem!important;
}
.badge-success {
  color: #fff !important;
  background-color: #28a745 !important;
}
.mr-2 {
  margin-right: 2%;
}
.margin-right-1 {
  margin-right: 1%;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.max-w20 {
  max-width: 20px;
}
// .ng2-pdf-viewer-container {
// overflow-x: unset !important;
// // position: relative !important;
// min-height: 29cm;
// }
// .pdf-container {
//   all:revert;
//   all:default;
// }
.text-left{
  text-align: left;
}
.invalid-field {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.maxwidth-border-0 {
  max-width: 100%;
  width: 100%;
  border: 0px
}